import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentTooltip.figmaUrl.ios} codeUrl={checklists.componentTooltip.codeUrl.ios} checklists={checklists.componentTooltip.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`In general, there are two types of LGNTooltip content that you can use:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Text Tooltip`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-none.png",
          "alt": "Text Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Custom View Tooltip`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-custom-bottom-center.png",
          "alt": "Custom View Tooltip",
          "width": 200
        }}></img>
      </li>
    </ul>
    <p>{`These tooltips can be shown in four positions:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Top Center`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-top-center.png",
          "alt": "Top Center Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bottom Center`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-bottom-center.png",
          "alt": "Bottom Center Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Left`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-left.png",
          "alt": "Left Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Right`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-right.png",
          "alt": "Right Tooltip",
          "width": 200
        }}></img>
      </li>
    </ul>
    <p>{`Additionally, tooltips support nine different arrow positions:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`None (no arrow)`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-none.png",
          "alt": "No Arrow Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Right`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-right.png",
          "alt": "Right Arrow Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Left`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-left.png",
          "alt": "Left Arrow Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bottom Center`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-bottom-center.png",
          "alt": "Bottom Center Arrow Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bottom Left`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-bottom-left.png",
          "alt": "Bottom Left Arrow Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bottom Right`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-bottom-right.png",
          "alt": "Bottom Right Arrow Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Top Left`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-top-left.png",
          "alt": "Top Left Arrow Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Top Center`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-top-center.png",
          "alt": "Top Center Arrow Tooltip",
          "width": 200
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Top Right`}</p>
        <img parentName="li" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/tooltip/ios-uikit/ios-uikit-tooltip-text-top-right.png",
          "alt": "Top Right Arrow Tooltip",
          "width": 200
        }}></img>
      </li>
    </ul>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`LGNTooltip has 2 content variants:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Text`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Custom View`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simple text tooltip`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Custom view with any UI components`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Basic text tooltip:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let tooltip = BaseLGNTooltip()
tooltip.tooltipPosition = .bottomCenter
tooltip.setContent(.text("This is a tooltip"))
tooltip.show(in: parentView, relativeTo: buttonView)
`}</code></pre>
    <p>{`Custom view tooltip:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let tooltip = BaseLGNTooltip()
tooltip.tooltipPosition = .topCenter
let customView = UIView()
// Configure your custom view
tooltip.setContent(.custom(customView))
tooltip.show(in: parentView, relativeTo: buttonView)
`}</code></pre>
    <p>{`Auto-dismissing tooltip:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let tooltip = BaseLGNTooltip()
tooltip.tooltipPosition = .right
tooltip.setContent(.text("Will dismiss in 5 seconds"))
tooltip.show(for: 5, in: parentView, relativeTo: buttonView)
`}</code></pre>
    <p>{`Non-dismissible tooltip:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let tooltip = BaseLGNTooltip()
tooltip.tooltipPosition = .left
tooltip.setContent(.text("Can't dismiss this!"))
tooltip.setDismissible(false)
tooltip.show(in: parentView, relativeTo: buttonView)
`}</code></pre>
    <div className="divi" />
    <h2>{`Methods`}</h2>
    <p>{`The tooltip provides several methods for controlling its behavior:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Show tooltip
tooltip.show(in: parentView, relativeTo: referenceView)

// Show tooltip with auto-dismiss
tooltip.show(for: timeInterval, in: parentView, relativeTo: referenceView)

// Set content
tooltip.setContent(.text("Your text"))
tooltip.setContent(.custom(yourView))

// Control dismissibility
tooltip.setDismissible(true/false)

// Manually dismiss
tooltip.dismiss()
`}</code></pre>
    <div className="divi" />
    <h2>{`Example Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`class ViewController: UIViewController {

    private let exclamationMarkButton: UIButton = {
        let button = UIButton(type: .system)
        button.setImage(UIImage(systemName: "exclamationmark.circle"), for: .normal)
        button.translatesAutoresizingMaskIntoConstraints = false
        return button
    }()

    override func viewDidLoad() {
        super.viewDidLoad()

        view.addSubview(exclamationMarkButton)

        NSLayoutConstraint.activate([
            exclamationMarkButton.centerXAnchor.constraint(equalTo: view.centerXAnchor),
            exclamationMarkButton.centerYAnchor.constraint(equalTo: view.centerYAnchor)
        ])

        exclamationMarkButton.addTarget(self, action: #selector(showTooltip), for: .touchUpInside)
    }

    @objc private func showTooltip() {

        let mainStack = UIStackView()
        mainStack.axis = .vertical
        mainStack.spacing = 8
        mainStack.alignment = .fill
        mainStack.translatesAutoresizingMaskIntoConstraints = false

        let storeRegionStack = createInfoRow(title: "Store Region", value: "Midwest", showColorIndicator: true)
        let productTypeStack = createInfoRow(title: "Product Type", value: "Entertainment", showIconIndicator: true)
        let revenueStack = createInfoRow(title: "Total Revenue", value: "$292M")

        mainStack.addArrangedSubview(storeRegionStack)
        mainStack.addArrangedSubview(productTypeStack)
        mainStack.addArrangedSubview(revenueStack)

        let tooltip = LGNTooltip()
        tooltip.setContent(.custom(mainStack))
        tooltip.position = .bottomCenter
        tooltip.tooltipPosition = .topCenter
        tooltip.setDismissible(true)
        tooltip.show(for: 3, in: self.view, relativeTo: exclamationMarkButton)
    }

    func createInfoRow(title: String, value: String, showColorIndicator: Bool = false, showIconIndicator: Bool = false) -> UIStackView {
        let rowStack = UIStackView()
        rowStack.axis = .horizontal
        rowStack.spacing = 8
        rowStack.alignment = .center

        if showColorIndicator {
            let colorIndicator = UIView()
            colorIndicator.backgroundColor = .systemYellow // Or your desired color
            colorIndicator.translatesAutoresizingMaskIntoConstraints = false
            NSLayoutConstraint.activate([
                colorIndicator.widthAnchor.constraint(equalToConstant: 12),
                colorIndicator.heightAnchor.constraint(equalToConstant: 12)
            ])
            rowStack.addArrangedSubview(colorIndicator)
        }

        if showIconIndicator {
            let icon = UIImageView(image: UIImage(systemName: "play.circle"))
            icon.tintColor = .black
            icon.contentMode = .scaleAspectFit
            icon.translatesAutoresizingMaskIntoConstraints = false
            NSLayoutConstraint.activate([
                icon.widthAnchor.constraint(equalToConstant: 16),
                icon.heightAnchor.constraint(equalToConstant: 16)
            ])
            rowStack.addArrangedSubview(icon)
        }

        let titleLabel = UILabel()
        titleLabel.text = title
        titleLabel.font = UIFont.systemFont(ofSize: 12)
        titleLabel.textColor = .gray

        let valueLabel = UILabel()
        valueLabel.text = value
        valueLabel.font = UIFont.systemFont(ofSize: 12)
        valueLabel.textColor = .black
        valueLabel.setContentHuggingPriority(.defaultLow, for: .horizontal)

        rowStack.addArrangedSubview(titleLabel)
        rowStack.addArrangedSubview(valueLabel)

        return rowStack
    }
}
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`containerView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The main container view that holds the tooltip content. Has white background and shadow.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`UIView`}</inlineCode>{` with white background, corner radius 8, and shadow`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`contentView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The view that contains the actual content (label or custom view).`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Empty `}<inlineCode parentName="td">{`UIView`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isDismissible`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Determines if the tooltip can be dismissed by tapping the reference view.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label used to display text content in the tooltip.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`UILabel`}</inlineCode>{` with NunitoSans font, size 12, black text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`position`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The position of the arrow pointing from the tooltip. Can be `}<inlineCode parentName="td">{`.none`}</inlineCode>{`, `}<inlineCode parentName="td">{`.right`}</inlineCode>{`, `}<inlineCode parentName="td">{`.left`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottomCenter`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottomLeft`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottomRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`.topLeft`}</inlineCode>{`, `}<inlineCode parentName="td">{`.topCenter`}</inlineCode>{`, or `}<inlineCode parentName="td">{`.topRight`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.none`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`referenceView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The view that the tooltip is associated with and points to.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tooltipPosition`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The position where the tooltip should appear relative to the reference view. Can be `}<inlineCode parentName="td">{`.right`}</inlineCode>{`, `}<inlineCode parentName="td">{`.left`}</inlineCode>{`, `}<inlineCode parentName="td">{`.bottomCenter`}</inlineCode>{`, or `}<inlineCode parentName="td">{`.topCenter`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bottomCenter`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      